<template>
  <div>
    <div>
      <div
        v-if="loader"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col lg="12">
          <b-card>
            <validation-observer
              ref="notifyForm"
              tag="form"
            >
              <b-row>
                <b-col
                  md="6"
                  class="hasEmoji"
                >
                  <validation-provider
                    #default="validationContext"
                    rules="required"
                    name="Notification Title"
                  >
                    <b-form-group
                      label="Notification Title"
                      label-for="Notification Title"
                    >

                      <b-form-input
                        id="Notification Title"
                        v-model="notifyData.title"
                        type="text"
                        placeholder="Notification Title"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <span
                    class="emoji-button-input"
                    @click="toggleEmojiPickerInput"
                  >
                    😀
                  </span>
                  <picker
                    v-if="isEmojiPickerVisibleInput"
                    :style="{ position: 'absolute', top: '60px', right: '30px' }"
                    @select="insertEmojiInput"
                  />
                </b-col>
                <b-col cols="6">
                  <b-media>

                    <b-media-aside>

                      <b-img
                        ref="previewEl"
                        v-img
                        :src="preview"
                        class="ml-1 "
                        width="100"
                      />

                      <!--/ image -->
                    </b-media-aside>
                    <b-media-body class="mt-75 ml-75">
                      <!-- upload button -->
                      <b-button
                        variant="primary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="selectFile()"
                      >
                        Upload Image
                      </b-button>
                      <b-form-file
                        ref="refInputEl"
                        accept=".jpg, .png"
                        :hidden="true"
                        plain
                        @change="changeProfile($event)"
                      />
                      <!--/ upload button -->
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  md="6"
                  class="hasEmoji"
                >
                  <validation-provider
                    #default="validationContext"
                    rules="required"
                    name="Notification Body"
                  >
                    <b-form-group
                      label="Notification Body"
                      label-for="Notification Body"
                    >

                      <b-form-textarea
                        id="Notification Body"
                        v-model="notifyData.body"
                        trim
                        type="text"
                        placeholder="Notification Body"
                        :state="getValidationState(validationContext)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <span
                    class="emoji-button"
                    @click="toggleEmojiPicker"
                  >
                    😀
                  </span>
                  <picker
                    v-if="isEmojiPickerVisible"
                    :style="{ position: 'absolute', top: '60px', right: '30px' }"
                    @select="insertEmoji"
                  />
                </b-col>

                <b-col
                  cols="12"
                  class="mt-50"
                >
                  <b-button
                    v-if="!loader"
                    variant="primary"
                    class="mr-1"
                    @click="pushNotification()"
                  >
                    Send
                  </b-button>
                  <b-button
                    v-if="loader"
                    variant="primary"
                    disabled
                    class="mr-1"
                  >
                    <b-spinner
                      small
                      type="grow"
                    />
                    Loading...
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import { Picker } from 'emoji-mart-vue'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import previewImage from '@/global-compositions/previewImage'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {
    Picker,
  },
  mixins: [togglePasswordVisibility, previewImage],
  data() {
    return {
      required,
      loader: false,
      notifyData: {
        title: '',
        body: '',
      },
      product_gifts: [],
      userIds: '',
      pagination: {
        current_page: 1,
      },
      types: [{
        id: 1,
        name: 'Destination',
      },
      {
        id: 2,
        name: 'Campaign',
      },
      ],
      preview: null,
      observer: null,
      limit: 15,
      search: '',
      ad_image: '',
      isEmojiPickerVisible: false,
      isEmojiPickerVisibleInput: false,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const { formData, setFormData } = handleFormData()
    const allUsers = ref([])

    return {
      getValidationState,
      formData,
      setFormData,
      allUsers,
    }
  },
  computed: {
    filtered() {
      return this.allUsers
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.pagination.current_page < this.pagination.last_page
    },
  },
  mounted() {
    this.getproduct_gifts()

    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },

    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.ad_image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.ad_image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    toggleEmojiPicker() {
      this.isEmojiPickerVisible = !this.isEmojiPickerVisible
    },
    toggleEmojiPickerInput() {
      this.isEmojiPickerVisibleInput = !this.isEmojiPickerVisibleInput
    },
    insertEmoji(emoji) {
      this.notifyData.body += emoji.native
      this.toggleEmojiPicker()
    },
    insertEmojiInput(emoji) {
      this.notifyData.title += emoji.native
      this.toggleEmojiPickerInput()
    },
    onInput(event) {
      this.notifyData.title = event.data
    },
    onInputBody(event) {
      this.notifyData.body = event.data
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async searchproduct_gifts(searchQuery) {
      await axios.get(`admin/product_gifts?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.product_gifts = res.data?.data.product_gifts
        }
      })
    },
    async getproduct_gifts() {
      await axios.get('admin/product_gifts').then(res => {
        if (res.status === 200) {
          this.product_gifts = res.data?.data.products
        }
      })
    },
    async searchUsers(searchQuery) {
      await axios.get(`users?search=${searchQuery}&role=2`).then(res => {
        if (res.status === 200) {
          this.allUsers = res.data?.data
          this.pagination = res.data.meta
        }
      })
    },
    async infiniteScroll([{ isIntersecting }]) {
      if (isIntersecting) {
        this.pagination.current_page += 1
        axios
          .get(`users?page=${this.pagination.current_page}`)
          .then(response => {
            if (response.data.data.length > 1) {
              response.data.data.forEach(item => this.allUsers.push(item))
              this.limit += 15
            }
          })
      }
    },

    pushNotification() {
      this.$refs.notifyForm.validate().then(success => {
        if (success) {
          this.loader = true
          this.setFormData(this.notifyData)
          this.formData.append('image', this.ad_image)
          axios
            .post(
              'admin/send_notification',
              this.formData,
            )
            .then(res => {
              if (res.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sent Succesfully',
                    icon: 'BellIcon',
                    variant: 'success',
                  },
                })
              }
            })
            .catch(error => {
              if (error.response.status === 500) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Somthing Went Wrong',
                    icon: 'BellIcon',
                    variant: 'error',
                  },
                })
              }
            })
            .finally(() => {
              this.loader = false
              this.notifyData = {}
              this.preview = ''
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.emoji-button{
  position: absolute;
    top: 30%;
    right: 3%;
    cursor: pointer;
}
.emoji-button-input{
  position: absolute;
    top: 40%;
    right: 3%;
    cursor: pointer;
}
.hasEmoji{
  position: relative;
}
</style>
